import React, { useEffect, useRef } from 'react'
import MaxWidth from 'components/global/maxWidth'
import styled from 'styled-components'
import Slider from 'react-slick'
import SinglePhotographer from 'components/photographers/singlePhotographers'
import ArrowIcon from 'svgs/arrow.svg'
import { media } from 'utils/Media'

const ViewPhotographersStyles = styled.section`
  width: 100%;
  padding: 3rem 0;
  .title {
    text-align: center;
    p,
    h2 {
      text-transform: uppercase;
      color: var(--background);
    }
    p {
      font-weight: 500;
    }
    h2 {
      margin-top: 0.5rem;
      font-size: 1.85rem;
      @media ${media.sm} {
        font-size: 2.5rem;
      }
      @media ${media.lg} {
        font-size: 3rem;
      }
    }
  }
  .slider {
    position: relative;
    width: 100%;
    margin: 1.5rem auto;
    @media ${media.sm} {
      margin: 2rem 0;
    }
    @media ${media.lg} {
      margin: 2.5rem auto;
    }
    @media ${media.xl} {
      width: 90%;
    }
  }
`
const LeftArrow = styled.div`
  cursor: pointer;
  fill: var(--main);
  width: 35px;
  height: 35px;
  position: absolute;
  left: -10px;
  top: 30%;
  @media ${media.mediumMobile} {
    width: 30px;
    height: 30px;
  }
  @media ${media.sm} {
    top: 25%;
    left: -1rem;
  }
  @media ${media.md} {
    left: -1.5rem;
  }
  @media ${media.largeTablet} {
    top: 32%;
  }
  @media ${media.xl} {
    left: -3.5rem;
    svg {
      width: 55px;
      height: 55px;
    }
  }
`
const RightArrow = styled.div`
  cursor: pointer;
  fill: var(--main);
  width: 35px;
  height: 35px;
  transform: scale(-1);
  position: absolute;
  right: -10px;
  top: 30%;
  @media ${media.mediumMobile} {
    width: 30px;
    height: 30px;
  }
  @media ${media.sm} {
    top: 25%;
    right: -1rem;
  }
  @media ${media.md} {
    right: -1.5rem;
  }
  @media ${media.largeTablet} {
    top: 32%;
  }
  @media ${media.xl} {
    right: -3.5rem;
    svg {
      width: 55px;
      height: 55px;
    }
  }
`
const ViewPhotographers = ({ data }) => {
  const sliderRef = useRef()
  const settings = {
    dots: false,
    arrows: false,
    speed: 600,
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const next = () => {
    sliderRef.current.slickNext()
  }
  const prev = () => {
    sliderRef.current.slickPrev()
  }

  return (
    <MaxWidth>
      <ViewPhotographersStyles>
        <div className="title">
          <p>View all our</p>
          <h2>Photographers</h2>
        </div>
        <div className="slider">
          <Slider {...settings} ref={sliderRef}>
            {data.map((photographer, i) => (
              <SinglePhotographer data={photographer} key={i} $slider />
            ))}
          </Slider>
          <LeftArrow onClick={() => prev()}>
            <ArrowIcon />
          </LeftArrow>
          <RightArrow onClick={() => next()}>
            <ArrowIcon />
          </RightArrow>
        </div>
      </ViewPhotographersStyles>
    </MaxWidth>
  )
}

export default ViewPhotographers
