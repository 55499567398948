import React from 'react'

import Layout from 'components/global/layout'
import Hero from 'components/index/hero'
import PageIntro from 'components/global/pageIntro'
import ViewPhotographers from 'components/index/viewPhotographers'
import { graphql } from 'gatsby'
import Portraits from 'components/index/portraits'

const IndexPage = ({ data }) => {
  const photographers = data.photographers.nodes
  const portraits = data.portraits.nodes
  return (
    <Layout>
      <Hero />
      <PageIntro
        title="Portraits of Holocaust Survivors"
        text="Generations: Portraits of Holocaust survivors features over 50 contemporary portraits of Holocaust survivors, from 13 esteemed photographers, including Her Royal Highness The Duchess of Cambridge. The full exhibition, previously on display at Imperial War Museum London with the support of Dangoor Education, is now available to view digitally here. This is brought to you through a partnership between Jewish News, Holocaust Memorial Day Trust and Royal Photographic Society."
      />
      <Portraits data={portraits} />
      <ViewPhotographers data={photographers} />
    </Layout>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query IndexQuery {
    portraits: allIndexJson {
      nodes {
        name
        bio
        index
        photographer
        coverImage {
          childImageSharp {
            gatsbyImageData(width: 1000, quality: 100)
          }
        }
        galleryImage {
          childImageSharp {
            gatsbyImageData(width: 1400, quality: 100)
          }
        }
      }
    }
    photographers: allPhotographersJson {
      nodes {
        name
        img
      }
    }
  }
`
