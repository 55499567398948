import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import { media } from 'utils/Media'

const HeroStyles = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  section {
    width: 90%;
    padding-top: 5rem;
    margin: var(--auto);
    position: relative;
    z-index: 5;
    text-align: center;
    strong {
      display: block;
      font-weight: 600;
      color: var(--alt);
      @media ${media.md} {
        font-size: 1.5rem;
      }
    }
    h1 {
      color: var(--alt);
      margin: 0.25rem 0;
      padding: 0;
      font-size: 2.25rem;
      font-weight: 600;
      @media ${media.mediumMobile} {
        font-size: 2.5rem;
      }
      @media ${media.sm} {
        font-size: 2.85rem;
      }
      @media ${media.md} {
        font-size: 3rem;
      }
      @media ${media.lg} {
        font-size: 3.5rem;
      }
    }
    .buttons {
      width: 80%;
      margin: 1rem auto 0 auto;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      button {
        max-width: 350px;
        @media ${media.md} {
          span {
            font-size: 1.15rem;
          }
        }
      }
    }
  }

  .hero-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.45);
      z-index: 1;
      content: '';
    }
  }
`
const Hero = () => {
  const i = true
  return (
    <HeroStyles>
      <section>
        <h1>
          GENERATIONS: PORTRAITS <br /> OF HOLOCAUST SURVIVORS
        </h1>
        <div className="buttons">
          <Link to="/gallery">
            <button type="button" className="btn btn-main">
              <span>VIEW GALLERY</span>
            </button>
          </Link>
        </div>
      </section>
      <div className="hero-image">
        <StaticImage src="../../images/tom-hunter.png" alt="Generations" />
      </div>
    </HeroStyles>
  )
}

export default Hero
