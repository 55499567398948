import React from 'react'
import styled, { css } from 'styled-components'

import GetImage from 'components/functional/getImage'
import Arrow from 'svgs/simple-arrow.svg'
import { media } from 'utils/Media'
import { Link } from 'gatsby'

const PortraitsStyles = styled.section`
  width: 100%;
`
const SinglePortraitStyles = styled.section`
  width: 100%;
  padding: 3rem 0;
  section {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    .img {
      width: 95%;
      position: relative;
      z-index: 5;
      &::after {
        position: absolute;
        top: -1rem;
        right: -1rem;
        width: 100%;
        height: 100%;
        content: '';
        z-index: -1;
      }
    }
    .text {
      padding: 1.5rem 0;
      strong {
        color: var(--main);
        text-transform: capitalize;
        font-weight: 600;
        @media ${media.lg} {
          font-size: 1.2rem;
        }
      }
      h3 {
        font-weight: 400;
        margin: 1.25rem 0;
        font-size: 1.5rem;
        @media ${media.mediumMobile} {
          font-size: 1.75rem;
        }
        @media ${media.lg} {
          font-size: 2rem;
        }
      }
      .photographer {
        margin-top: 1rem;
        font-weight: 600;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        a,
        span {
          display: block;
        }
        a {
          line-height: 18px;
          color: var(--main);
          border-bottom: solid 2px var(--main);
        }
        .icon {
          width: 20px;
          height: 100%;
          border: none;
          display: flex;
          align-items: center;
        }
        @media ${media.mediumMobile} {
          font-size: 1rem;
        }
        @media ${media.lg} {
          font-size: 1.2rem;
        }
      }
      max-width: 400px;
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
      .text {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    @media ${media.lg} {
      grid-gap: 1rem;
    }
  }
  @media ${media.md} {
    padding: 4rem 0;
  }
  ${props =>
    props.$left &&
    css`
      background-color: var(--alt);
      .img {
        &::after {
          background: var(--white);
          @media ${media.md} {
            left: -1rem;
            right: 100%;
          }
        }
      }
      @media ${media.lg} {
        section {
          grid-template-columns: 55% 1fr;
        }
      }
      @media ${media.xl} {
        section {
          grid-template-columns: 60% 1fr;
        }
      }
    `}
  ${props =>
    props.$right &&
    css`
      background-color: var(--white);
      .img {
        &::after {
          background: var(--alt);
        }
      }
      @media ${media.md} {
        section {
          grid-template-areas: 'a b';

          .img {
            grid-area: b;
          }
        }
      }
      @media ${media.lg} {
        section {
          grid-template-columns: 1fr 55%;
        }
      }
      @media ${media.xl} {
        section {
          grid-template-columns: 1fr 60%;
        }
      }
    `}
`
const Portraits = ({ data }) => {
  const x = true
  return (
    <PortraitsStyles>
      {/* //* apply $right prop to every second item to change style */}
      {data.map((photo, i) => {
        const right = i % 2 === 1
        return right ? (
          <SinglePortrait $right data={photo} key={i} />
        ) : (
          <SinglePortrait $left data={photo} key={i} />
        )
      })}
    </PortraitsStyles>
  )
}

export default Portraits

const SinglePortrait = props => (
  <SinglePortraitStyles {...props}>
    <section>
      <div className="img">
        <Link
          to={`/gallery/#${props.data.index}`}
          state={{ index: props.data.index }}
        >
          <GetImage
            data={props.data.coverImage}
            alt={`${props.data.name} | Generations`}
          />
        </Link>
      </div>
      <div className="text">
        <strong>Portraits of Holocaust Survivors</strong>
        <h3>{props.data.name}</h3>
        <p>{props.data.bio}</p>
        <p className="photographer">
          {`Photograph by ${props.data.photographer}`}
        </p>
      </div>
    </section>
  </SinglePortraitStyles>
)
